// src/App.js

import React, { useState, useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // Importación corregida
import { CssBaseline, Snackbar, Alert, CircularProgress, Box } from '@mui/material';

import ProtectedRoute from './pages/components/ProtectedRoute';
import LoginForm from './pages/components/LoginForm';
import Layout from './pages/components/Layout';

// Uso de lazy para carga diferida de páginas
const HomePage = lazy(() => import('./pages/HomePage'));
const UserPage = lazy(() => import('./pages/UserPage'));
const ProductPage = lazy(() => import('./pages/ProductsPage'));
const ProductView = lazy(() => import('./pages/ProductView'));
const ProductEdit = lazy(() => import('./pages/ProductEdit'));
const ProductCreate = lazy(() => import('./pages/ProductCreate'));
const ProductBulkUpload = lazy(() => import('./pages/ProductBulkUpload'));
const CategoryCreate = lazy(() => import('./pages/CategoryCreate'));
const CategoryPage = lazy(() => import('./pages/CategoryPage'));
const IntegrationPage = lazy(() => import('./pages/IntegrationPage'));
const IntegrationView = lazy(() => import('./pages/IntegrationView'));
const IntegrationEdit = lazy(() => import('./pages/IntegrationEdit'));
const IntegrationCreate = lazy(() => import('./pages/IntegrationCreate'));
const OrdersPage = lazy(() => import('./pages/OrdersPage'));
const OrderCreatePage = lazy(() => import('./pages/OrderCreatePage'));
const OrderEditPage = lazy(() => import('./pages/OrderEditPage'));
const OrderDetailsPage = lazy(() => import('./pages/OrderDetailsPage'));
const JumpsellerOrdersPage = lazy(() => import('./pages/JumpsellerOrdersPage'));
const JumpsellerOrdersView = lazy(() => import('./pages/JumpsellerOrdersView'));
const AutomationPage = lazy(() => import('./pages/AutomationPage'));
const AutomationForm = lazy(() => import('./pages/AutomationForm'));
const JumpsellerProductsPage = lazy(() => import('./pages/JumpsellerProductsPage'));
const JumpsellerProductView = lazy(() => import('./pages/JumpsellerProductView'));
const PurchasesPage = lazy(() => import('./pages/PurchasesPage'));
const PurchaseDetailsView = lazy(() => import('./pages/PurchaseDetailsView'));
const InventoryPage = lazy(() => import('./pages/InventoryPage'));
const SettingsPage = lazy(() => import('./pages/SettingsPage'));
const SuppliersPage = lazy(() => import('./pages/SuppliersPage'));
const SalesPage = lazy(() => import('./pages/SalesPage'));
const SaleDetailsView = lazy(() => import('./pages/SaleDetailsView'));
const POSPage = lazy(() => import('./pages/POS'));
const SupplierProductsPage = lazy(() => import('./pages/SupplierProductsPage'));
const SuppliersBulkUploadPage = lazy(() => import('./pages/SuppliersBulkUploadPage'));
const QuotationPage = lazy(() => import('./pages/QuotationPage'));
const ImbraSyncPage = lazy(() => import('./pages/ImbraSyncPage'));
const WarehousesPage = lazy(() => import('./pages/WarehousesPage'));
const WarehouseCreate = lazy(() => import('./pages/WarehouseCreate'));
const WarehouseEdit = lazy(() => import('./pages/WarehouseEdit'));
const WarehouseView = lazy(() => import('./pages/WarehouseView'));

function App() {
  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [companySelected, setCompanySelected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const verifyAuth = () => {
    const token = localStorage.getItem('token');
    const tenantId = localStorage.getItem('tenantId');
    const companyId = localStorage.getItem('companyId');

    if (token && tenantId && companyId) {
      try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
          handleLogout();
        } else {
          setIsAuthenticated(true);
          setCompanySelected(true);
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          axios.defaults.headers.common['x-tenant-id'] = tenantId;
        }
      } catch (error) {
        console.error('Error decoding token:', error);
        handleLogout();
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    verifyAuth();

    const responseInterceptor = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          handleLogout();
          navigate('/login');
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = (data) => {
    setIsAuthenticated(true);
    setCompanySelected(true);
    localStorage.setItem('isAuthenticated', 'true');
    localStorage.setItem('token', data.token);
    localStorage.setItem('tenantId', data.company.tenant_id);
    localStorage.setItem('companyId', data.company.company_id);
    localStorage.setItem('companyName', data.company.company_name);
    localStorage.setItem('user', JSON.stringify(data.user));
    localStorage.setItem('company', JSON.stringify(data.company));
    axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
    axios.defaults.headers.common['x-tenant-id'] = data.company.tenant_id;
    setSnackbar({
      open: true,
      message: 'Inicio de sesión exitoso',
      severity: 'success',
    });
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setCompanySelected(false);
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('token');
    localStorage.removeItem('tenantId');
    localStorage.removeItem('companyId');
    localStorage.removeItem('companyName');
    localStorage.removeItem('user');
    localStorage.removeItem('company');
    delete axios.defaults.headers.common['Authorization'];
    delete axios.defaults.headers.common['x-tenant-id'];
    navigate('/login');
    setSnackbar({
      open: true,
      message: 'Sesión cerrada correctamente',
      severity: 'info',
    });
  };

  useEffect(() => {
    const handleStorageChange = () => {
      verifyAuth();
      const storedCompany = localStorage.getItem('companyId');
      setCompanySelected(!!storedCompany);
    };
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CssBaseline />
      <Suspense fallback={
        <Box
          sx={{
            display: 'flex',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      }>
        {isAuthenticated && companySelected ? (
          <Routes>
            <Route
              path="/pos"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <POSPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/*"
              element={
                <Layout onLogout={handleLogout}>
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <HomePage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/users"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <UserPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/products"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <ProductPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/products/view/:id"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <ProductView />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/products/edit/:id"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <ProductEdit />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/products/create"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <ProductCreate />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/products/bulk-upload"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <ProductBulkUpload />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/categories"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <CategoryPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/categories/create"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <CategoryCreate />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/integrations"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <IntegrationPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/integrations/view/:id"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <IntegrationView />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/integrations/edit/:id"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <IntegrationEdit />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/integrations/create"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <IntegrationCreate />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/purchases"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <PurchasesPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/purchases/:purchaseId"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <PurchaseDetailsView />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/orders"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <OrdersPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/orders/create"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <OrderCreatePage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/orders/edit/:id"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <OrderEditPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/orders/:id/details"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <OrderDetailsPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/jumpseller/orders"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <JumpsellerOrdersPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/orders/:orderId"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <JumpsellerOrdersView />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/jumpseller/products"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <JumpsellerProductsPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/jumpseller/products/:productId"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <JumpsellerProductView />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/automations"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <AutomationPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/automations/create"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <AutomationForm />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/inventory"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <InventoryPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/settings"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <SettingsPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/suppliers"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <SuppliersPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/suppliers/:tax_id"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <SupplierProductsPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/suppliers-bulk-upload"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <SuppliersBulkUploadPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/sales"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <SalesPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/quotation"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <QuotationPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/sales/:saleId"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <SaleDetailsView />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/imbrasync"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <ImbraSyncPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/warehouses"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <WarehousesPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/warehouses/create"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <WarehouseCreate />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/warehouses/edit/:id"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <WarehouseEdit />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/warehouses/:warehouseId"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <WarehouseView />
                        </ProtectedRoute>
                      }
                    />
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </Routes>
                </Layout>
              }
            />
          </Routes>
        ) : (
          <Routes>
            <Route path="/login" element={<LoginForm onLogin={handleLogin} />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        )}
      </Suspense>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
